<template>
    <b-modal id="newsModal" title="Neuigkeit" ok-only ok-variant="danger" ok-title="Speichern" size="xl" @ok="handleOk" @show="setupModal">
        <form ref="form" @submit.stop.prevent="handleSubmit" enctype="multipart/form-data">
            <div class="row">
                <div class="col-md-12">
                    <div class="form-group">
                        <label for="title" class="control-label">Thema*</label>
                        <input v-model="form.title" type="text" class="form-control form-cotrol-sm" :class="{'is-invalid': form.errors.has('title')}">
                        <has-error :form="form" field="title"></has-error>
                    </div>

                    <div class="form-group">
                        <label for="description" class="control-label">Beschreibung*</label>
                        <ckeditor :editor="editor" :config="editorConfig" v-model="form.description" :class="{'is-invalid': form.errors.has('description')}"></ckeditor>
                        <has-error :form="form" field="description"></has-error>
                    </div>

                    <div class="form-group">
                        <label for="company">Kategorie:</label>
                        <select class="form-control form-control-sm" v-model="form.topic_ids" multiple>
                            <option v-for="topic in allTopics" :key="topic.id" :value="topic.id">{{ topic.name }}</option>
                        </select>
                    </div>

                    <!-- <div class="form-group">
                        <label for="topic_ids" class="control-label">Kategorien</label>
                        <Select2 v-model="form.topic_ids" :options="allTopics" :settings="{ multiple: true }" :class="{'is-invalid': form.errors.has('topic_ids')}"/>
                        <has-error :form="form" field="topic_ids"></has-error>
                    </div> -->

                    <div class="form-group">
                        <label for="image" class="control-label">Bild</label>
                        <!-- <input v-model="form.logo" type="file" class="form-control form-control-sm" :class="{'is-invalid': form.errors.has('logo')}"> -->
                        <b-form-file id="image" v-model="form.image" :class="{'is-invalid': form.errors.has('image')}" />
                        <has-error :form="form" field="image"></has-error>
                    </div>
                </div>
            </div>
        </form>
    </b-modal>                        
</template>

<script>
    import ClassicEditor from '../../../assets/packages/ckeditor5/src/ckeditor';
    export default {

        props: [
            'news'
        ],

        data() {
            return {
                form: new window.UploadForm({
                    id: "",
                    title: "",
                    description: "",
                    date: "", 
                    user_id: "", 
                    topics: [], 
                    image: [],
                    _method: "POST",
                    topic_ids: [], 
                }),
                editor: ClassicEditor,
                editorConfig: {
                    link: {
                        decorators: {
                            openInNewTab: {
                                mode: 'manual',
                                label: 'Open in a new tab',
                                attributes: {
                                    target: '_blank',
                                    rel: 'noopener noreferrer'
                                }
                            }
                        }
                    },
                    simpleUpload: {
                        uploadUrl: process.env.VUE_APP_API_URL + 'images/upload',
                        withCredentials: true,
                        headers: {
                            'Accept': 'application/json',
                            'Authorization': 'Bearer '+ this.$auth.token()
                        }
                    }
                },
                editMode: false,
                allTopics: {}, 
            }
        }, 
        methods:{
            handleOk(bvModalEvt) {
                bvModalEvt.preventDefault()
                this.handleSubmit()
            },

            handleSubmit() {
                if(this.editMode == true)
                {
                    //Edit User
                    this.editNews();
                }
                else
                {
                    //Create User
                    this.createNews();
                }
            },

            createNews() {
                this.form
                    .post("/news")
                    .then(() => {
                        this.$bvModal.hide("newsModal");
                        this.$swal({
                            icon: "success",
                            title: "News Gespeichert!",
                            toast: true,
                            position: 'top-end',
                            showConfirmButton: false,
                            timer: 2000,
                            timerProgressBar: true,
                        });
                        this.$parent.loadNews();
                        this.form.clear(); 
                        this.form.reset(); 
                    })
                    .catch(() => {
                        this.$swal({
                            icon: "error",
                            title: "Oops...",
                            text: "Da ist etwas schief gelaufen",
                        });
                    });
            },

            editNews(){
                this.form
                    .post("/news/"+ this.news.id)
                    .then(() => {
                        this.$bvModal.hide("newsModal");
                        this.$swal({
                            icon: "success",
                            title: "News Gespeichert!",
                            toast: true,
                            position: 'top-end',
                            showConfirmButton: false,
                            timer: 2000,
                            timerProgressBar: true,
                        });
                        this.$parent.loadNews();
                        this.form.reset();
                        this.form.clear();
                        this.editMode = false;
                    })
                    .catch(() => {
                        this.$swal({
                            icon: "error",
                            title: "Oops...",
                            text: "Da ist etwas schief gelaufen",
                        });
                    });
            },

            loadallTopics() {
                this.axios
                    .get("/topics", {
                        params: {
                            per_page: 150, 
                        }
                    })        
                    .then((response) => {
                        this.allTopics = response.data.data;
                    })
                    .catch(() => {
                        this.$swal({
                            icon: "error",
                            title: "Oops...",
                            text: "Da ist etwas schief gelaufen",
                        });
                    });
            },

            setupModal(){
                this.loadallTopics(); 
                this.form.clear();
                this.form.reset();
            }
        },

        watch: {
            'news': function() {
                if(this.news != null && this.news != undefined){
                    this.form.clear();
                    this.form.reset();
                    this.form.fill(this.news);
                    this.editMode = true;
                    this.form._method = 'PUT';
                }
                else{
                    this.form.clear();
                    this.form.reset();
                    this.editMode = false;
                }
            },
        },  

        // mounted() {
        //     // this.loadallTopics(); 
        //     this.form.clear();
        //     this.form.reset();
        // },
    }
</script>

<style>
body {
        --ck-z-default: 100;
        --ck-z-modal: calc( var(--ck-z-default) + 999 );
    }
</style>