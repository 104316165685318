<template>
<div>
    <div class="container-fluid">
        <div class="row">
            <div class="col-md-12">
                <div class="card card-outline card-primary">
                    <div class="card-header">
                        <div class="card-title">
                            <h4>Alle Neuigkeiten in der Übersicht</h4>
                        </div>
                        <nav class="py-0 bg-white navbar navbar-expand navbar-light justify-content-end">
                            <ul class="navbar-nav">  
                                <li class="nav-item mr-1">
                                    <div class="input-group input-group-sm" style="margin-top: 0;">
                                        <input type="text" class="form-control" placeholder="Suchen" @keyup.enter="loadNews" :value="search" @input="search = $event.target.value" />
                                        <button type="submit" class="btn btn-default btn-sm" @click.prevent="loadNews">
                                            <i class="fas fa-search"></i>
                                        </button>
                                        <button v-if="this.search != ''" type="button" class="ml-1 btn btn-danger btn-sm" @click="resetSearch">
                                            <i class="fas fa-times"></i>
                                        </button>
                                    </div>
                                </li>
                                <li>
                                    <button type="button" class="mr-1 btn btn-default" @click="loadNews">
                                        <i class="fas fa-sync"></i>
                                    </button>
                                </li>
                                <li>
                                    <button type="button" class="btn btn-primary" @click="createModal" v-if="$auth.check('news.create')">
                                        <i class="fas fa-plus-circle"></i> Neuigkeit
                                    </button>
                                </li>
                            </ul>
                        </nav>
                    </div>

                    <div class="card-body">
                        <div class="table-responsive">
                            <table class="table table-bordered table-hover">
                                <thead>
                                    <tr>
                                        <th>
                                            Bild
                                        </th>
                                        <th>
                                            <a href="#" @click.prevent="changeSort('name')">
                                                Name
                                                </a>
                                            <span v-if="this.params.sort_field == 'name' && this.params.sort_direction == 'asc'" >&uarr;</span>
                                            <span v-if="this.params.sort_field == 'name' && this.params.sort_direction == 'desc'" >&darr;</span>
                                        </th>
                                        <th>
                                            <a href="#" @click.prevent="changeSort('slug')">
                                                Beschreibung 
                                            </a>
                                            <span v-if="this.params.sort_field == 'slug' && this.params.sort_direction == 'asc'" >&uarr;</span>
                                            <span v-if="this.params.sort_field == 'slug' && this.params.sort_direction == 'desc'" >&darr;</span>
                                        </th>
                                        <th>Angeheftet</th>
                                        <th>Kategorie</th>
                                        <th>Aktionen</th>
                                    </tr>
                                </thead>
                                <tbody v-for="news in news.data" :key="news.id">
                                    <!-- <tr data-widget="expandable-table" aria-expanded="false" v-for="news in news.data" :key="news.id">
                                        <td><img :src="news.img_mobile" width="90" height="60" /></td>
                                        <td>{{news.title}}</td>
                                        <td style="width: 60%">{{ news.description_short }}</td>
                                        <td><span v-if="news.pin == 1"><i class="fas fa-check"></i></td>
                                        <td><span v-for="topic in news.topics" :key="topic.id">{{ topic.name }}<br/></span></td>
                                        <td>
                                            <b-button size="xs" @click="editModal(news)" class="mr-1" variant="warning" v-if="$auth.check('news.edit')"><i class="fas fa-edit"></i></b-button>
                                            <b-button size="xs" @click="deleteNews(news.id)" variant="danger" v-if="$auth.check('news.destroy')"><i class="fas fa-fw fa-trash"></i></b-button>
                                        </td>
                                    </tr> -->
                                    <!-- <tr class="expandable-body">
                                        <td colspan="5">
                                        <p style="">
                                        <span v-html="news.description"></span>
                                        </p>
                                        </td>
                                    </tr> -->
                                    <tr data-widget="expandable-table" aria-expanded="true">
                                        <td>
                                            <img :src="news.img_mobile" width="90" height="60" />
                                        </td>
                                        <td>{{news.title}}</td>
                                        <td style="width: 40%">
                                            {{ news.description_short }}<br/><br/>
                                            <small v-if="news.description_short != ''" class="text-primary">Weiterlesen...</small>
                                        </td>
                                        <td><span v-if="news.pin == 1"><i class="fas fa-check"></i></span></td>
                                        <td><span v-for="topic in news.topics" :key="topic.id">{{ topic.name }}<br/></span></td>
                                        <td>
                                            <b-button size="xs" @click="editModal(news)" class="mr-1" variant="warning" v-if="$auth.check('news.edit')"><i class="fas fa-edit"></i></b-button>
                                            <b-button size="xs" @click="deleteNews(news.id)" variant="danger" v-if="$auth.check('news.destroy')"><i class="fas fa-fw fa-trash"></i></b-button>
                                        </td>
                                    </tr>
                                    <tr class="expandable-body d-none">
                                        <td colspan="8">
                                        <p style="display:none">
                                        <span v-html="news.description"></span>
                                        </p>
                                        </td>
                                    </tr> 
                                </tbody>
                            </table>
                        </div>
                    </div>
                    <div class="card-footer" v-if="notEmptyObject(news)">
                        <template v-if="news.data.length > 0">
                            <div class="row align-items-center">
                                <div class="col-md-9">
                                    <pagination class="float-left" :data="news" @pagination-change-page="loadNews" :limit="3"></pagination>
                                </div>
                                <!-- <div class="col-md-2">
                                    <span class="float-right">Anzeige Eintrag {{ producers.meta.from }} - {{ producers.meta.to }} von {{ producers.meta.total }}</span>
                                </div> -->
                                <div class="col-md-1">
                                    <select class="float-right form-control form-control-sm" v-model="params.per_page">
                                        <option value="25">25 Neuigkeiten</option>
                                        <option value="50">50 Neuigkeiten</option>
                                        <option value="75">75 Neuigkeiten</option>
                                        <option value="100">100 Neuigkeiten</option>
                                        <option value="125">125 Neuigkeiten</option>
                                        <option value="150">150 Neuigkeiten</option>
                                    </select>
                                </div>
                            </div>                        
                        </template>
                    </div>

                    <NewsModal :news="newsTransfer" />

                </div>          
            </div>
        </div>
    </div>
</div>
</template>

<script>
import NewsModal from '../News/components/Create';
// import ClassicEditor from '../../assets/packages/ckeditor5/src/ckeditor';

export default {
    name: "Neuigkeiten",
    title: "Neuigkeiten",
     components: {
      NewsModal,
    },
    data() {
        return {
            
            sortBy: "created_at",
            sortDesc: false,
            params: {
                sort_field: 'created_at',
                sort_direction: 'desc',
                per_page: 25,
            },
            search: '',
            page: 1,
            news: {},
            topics: {
                children: [], 
            },
            newsTransfer: null,
        }
    },
    watch: {
        params: {
            handler () {
                this.loadNews();
            },
            deep: true
        },
    },
    methods: {
        onRowDoubleClick(news){
            this.$router.push({name: 'news.details', params: {id: news.id}})
        },
        changeSort(field) {
            if(this.params.sort_field === field) {
                this.params.sort_direction = this.params.sort_direction == 'asc' ? 'desc' : 'asc';
    
            }
            else{
                this.params.sort_field = field;
                this.params.sort_direction = 'asc';
            }
        },
        resetSearch(){
            this.search = '';
            this.loadNews();
        },

        

        createModal() {
            // this.editMode = false;
            // this.modalTitle = "Neuigkeit anlegen";
            // this.form.clear();
            // this.form.reset();
            // this.form._method = 'POST';
            this.newsTransfer = null;
            this.$bvModal.show("newsModal");
        },

        editModal(News) {
            this.newsTransfer = News;
            this.$bvModal.show("newsModal");
        },

        deleteNews(id) {
            this.$swal({
                    title: "Möchtest du die Kategorie wirklich löschen?",
                    icon: "warning",
                    showCancelButton: true,
                    confirmButtonColor: "#3085d6",
                    cancelButtonColor: "#d33",
                    confirmButtonText: `Löschen`,
                    denyButtonText: `Abbrechen`,
                })
                .then((result) => {
                    /* Read more about isConfirmed, isDenied below */
                    if (result.isConfirmed) {
                        //Send request to the Server
                        this.axios
                            .delete("/news/" + id)
                            .then(() => {
                                this.$swal({
                                    icon: "success",
                                    title: "Die Kategorie wurde erfolgreich gelöscht!",
                                    toast: true,
                                    position: 'top-end',
                                    showConfirmButton: false,
                                    timer: 2000,
                                    timerProgressBar: true,
                                    });
                                this.loadNews();
                            })
                            .catch(() => {
                                this.$swal({
                                    icon: "error",
                                    title: "Oops...",
                                    text: "Da ist etwas schief gelaufen",
                                });
                            });
                    }
                });
        },

        createNews() {
            if(this.usePin == true) {
                this.form.pin = 1; 
            }
            else {
                this.form.pin = 0; 
            }
            this.form
                .post("/news")
                .then(() => {
                    this.$bvModal.hide("newsModal");
                    this.$swal({
                        icon: "success",
                        title: "Die Neuigkeit wurde angelegt",
                        toast: true,
                        position: 'top-end',
                        showConfirmButton: false,
                        timer: 2000,
                        timerProgressBar: true,
                    });
                    this.loadNews();
                })
                .catch(() => {
                    this.$swal({
                        icon: "error",
                        title: "Oops...",
                        text: "Da ist etwas schief gelaufen",
                    });
                });
        },

        editNews(id){
            if(this.usePin == true) {
                this.form.pin = 1; 
            }
            else {
                this.form.pin = 0; 
            }
            this.form
                .post("/news/"+ id)
                .then(() => {
                    this.$bvModal.hide("newsModal");
                    this.$swal({
                        icon: "success",
                        title: "Die Neuigkeit wurde editiert",
                    });
                    this.loadNews();
                })
                .catch(() => {
                    this.$swal({
                        icon: "error",
                        title: "Oops...",
                        text: "Da ist etwas schief gelaufen",
                    });
                });
        },


        loadNews(page) {
            if(page == undefined)
            {
                page = this.page 
            }
            this.axios
                .get("/news", {
                    params: {
                        page: page,
                        search: this.search,
                        ...this.params
                    }
                })
                .then((response) => {
                    this.news = response.data;
                })
                .catch(() => {
                    this.$swal({
                        icon: "error",
                        title: "Oops...",
                        text: "Da ist etwas schief gelaufen",
                    });
                });
        },
        loadTopics() {
            this.axios
                .get("/topics")
                .then((response) => {
                    this.topics = response.data.data;
                })
                .catch(() => {
                    this.$swal({
                        icon: "error",
                        title: "Oops...",
                        text: "Da ist etwas schief gelaufen",
                    });
                });
        },
    },

    created() {
        this.loadNews();
        this.loadTopics(); 
    },
}
</script>

<style scoped></style>
